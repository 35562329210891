import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { injected, chainData } from "./connector/Connecter";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import logo from "./load.gif";

<img src={logo} alt="" />;
export const Mycontext = createContext();

function MyContext() {
  var { active, account, activate, deactivate, error, library } =
    useWeb3React();

  const formateNumber = (num) => {
    let number = Number(num);
    let finalNum = "";
    if (number > 1000000) {
      finalNum = (num / 1000000).toFixed(1).toString() + "M+";
    }
    return finalNum;
  };
  const [rate, setRate] = useState("");
  const [totalsupply, setTotalsupply] = useState("");
  const [marketCap, setMarketCap] = useState("");
  const [CirculatingSupply, setCirculatingSupply] = useState("");
  const [totalaccount, setTotalAccount] = useState("");
  const getData = async () => {
    const data = await axios.get(
      "https://api.ctexcoin.io/api/price",
      {}
    );
    // console.log("data : ", data);
    setRate(data.data.price.toFixed(4));
    setTotalsupply(data.data.total_supply);
    setMarketCap(data.data.fully_diluted_market_cap);
    setCirculatingSupply(data.data.CirculatingSupply);
  };
  const getAccount = async () => {
    const Accountdata = await axios.get(
      "https://ctexscan.com/api?module=stats&action=holders",
      {}
    );
    // console.log("data : ", Accountdata);
    setTotalAccount(Accountdata.data.result);

  };
  const [totalTransactions, setTotalTransactions] = useState(0);


  const TotalTransactionsData = async () => {
    try {
      const response = await axios.get('https://ctexscan.com/api?module=stats&action=totaltransactions');
      setTotalTransactions(response.data.result);
      console.log("setTotalTransactions", setTotalTransactions);

      // Assuming the response contains the total transactions
    } catch (error) {
      console.error('Error TotalTransactions data:', error);
    }
  };



  // const [web3, setWeb3] = useState(null);
  // const [validatorsCount, setValidatorsCount] = useState(0);
  // const [totalTransactions, setTotalTransactions] = useState(0);
  // const [totalHolders, setTotalHolders] = useState(0);

  // useEffect(() => {
  //   async function connectToBlockchain() {
  //     if (window.ethereum) {
  //       const web3Instance = new Web3(window.ethereum);
  //       setWeb3(web3Instance);
  //       try {
  //         // Request account access if needed
  //         await window.ethereum.request({ method: 'eth_requestAccounts' });
  //       } catch (error) {
  //         console.error('User denied account access');
  //       }
  //     } else {
  //       console.error('Please install MetaMask or another Ethereum-compatible browser extension');
  //     }
  //   }

  //   connectToBlockchain();
  // }, []);

  // useEffect(() => {
  //   async function fetchBlockchainData() {
  //     if (web3) {
  //       // Get validators count (if your custom chain has a specific API for this, replace the URL)
  //       const validatorsCount = await fetch('https://custom-evm-chain-api.com/validators-count');
  //       setValidatorsCount(validatorsCount);

  //       // Get total transactions count
  //       const blockNumber = await web3.eth.getBlockNumber();
  //       let totalTransactions = 0;
  //       for (let i = 0; i <= blockNumber; i++) {
  //         const block = await web3.eth.getBlock(i, true);
  //         if (block && block.transactions) {
  //           totalTransactions += block.transactions.length;
  //         }
  //       }
  //       setTotalTransactions(totalTransactions);

  //       // Get total holders count (if your custom chain has a specific API for this, replace the URL)
  //       const holdersCount = await fetch('https://custom-evm-chain-api.com/holders-count');
  //       setTotalHolders(holdersCount);
  //     }
  //   }

  //   fetchBlockchainData();
  // }, [web3]);

  useEffect(() => {
    getData();
    getAccount();
    TotalTransactionsData();
  }, []);
  return (
    <>
      <Toaster
        position="top-left"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#ffa518",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Mycontext.Provider
        value={{
          library,
          active,
          account: account ? account.toLowerCase() : account,
          formateNumber,
          rate,
          totalsupply,
          marketCap,
          CirculatingSupply,
          totalaccount,
          totalTransactions
        }}
      >
        <App />
      </Mycontext.Provider>
    </>
  );
}

export default MyContext;
