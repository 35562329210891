import { createContext } from "react";
import App from "./App";

export const RuptoContext = createContext();

const RuptoProvider = () => {
  const formateNumber = (num) => {
    let number = Number(num);
    let finalNum = "";
    if (number > 1000000) {
      finalNum = (num / 1000000).toFixed(1).toString() + "M+";
    }
    return finalNum;
  };

  return (
    <RuptoContext.Provider value={{ formateNumber }}>
      <App />
    </RuptoContext.Provider>
  );
};

export default RuptoProvider;
